body,
p {
  margin: 0;
}
h1, h2, h3, h4, h5{
  font-family: 'Roboto', sans-serif;
}
h1 {
  margin: 10px 0;
  font-size: 36px;
}

h2 {
  font-size: 36px;
  margin: 0;
}
h3 {
  font-size: 30px;
}
p {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}

@media all and (min-width: 768px) {
  h1 {
    font-size: 72px;
    margin: 0;
  }
  h2 {
    font-size: 54px;
  }
  h3 {
    font-size: 30px;
  }
  p {
    font-size: 20px;
  }
}
